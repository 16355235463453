@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply bg-gray-50 tracking-tight;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.btn-primary {
  @apply bg-primary-default text-neutural-strong rounded-full font-medium tracking-tight flex justify-center items-center;
  height: var(--size-48, 48px);
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 88.54%
  );
  box-shadow: 0px -2.5px 0px 0px rgba(159, 232, 112, 0.6) inset,
    0px 1.5px 0px 0px rgba(255, 255, 255, 0.2) inset;
  padding: var(--Padding-Small, 12px) var(--Padding-Large, 20px);
}

.btn-secondary {
  @apply bg-vintageGreen-800 text-white rounded-full font-medium tracking-tight;
  height: var(--size-48, 48px);
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 9.38%,
    rgba(0, 0, 0, 0) 88.54%
  );
  box-shadow: 0px -2.5px 0px 0px #2b4423 inset,
    0px 1.5px 0px 0px rgba(255, 255, 255, 0.2) inset;
  padding: var(--Padding-Small, 12px) var(--Padding-Large, 20px);
}

.icon-btn {
  @apply bg-transparent border border-neutural-strong text-neutural-strong rounded-full font-medium tracking-tight flex justify-center items-center;
  height: var(--size-48, 48px);
  width: var(--size-48, 48px);
}

.btn-outline {
  @apply bg-transparent border border-neutural-strong text-neutural-strong rounded-full font-medium tracking-tight flex justify-center items-center py-2 px-3;
  height: var(--size-48, 48px);
}

.btn-danger {
  @apply bg-vintageRed-50 text-vintageRed-600 rounded-full font-medium tracking-tight flex justify-center items-center py-2 px-3;
  height: var(--size-48, 48px);
}

.btn-danger-outline {
  @apply bg-vintageRed-50 text-vintageRed-600 rounded-full font-medium tracking-tight flex justify-center items-center py-2 px-3;
  height: var(--size-48, 48px);
}

.card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.overlay {
  z-index: 1;
  position: fixed;
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  @apply backdrop-filter backdrop-blur-[5px] bg-black bg-opacity-55;
}

.card-content {
  position: relative;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.82);
  backdrop-filter: blur(10px);
  overflow: hidden;
}

/* .open .card-content {
  max-height: 720px;
  max-width: 70vw;
  width: 100%;
} */

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.title-container {
  position: absolute;
  top: 15px;
  left: 15px;
}

.open .title-container {
  top: 30px;
  left: 30px;
}

.divider-text {
  position: relative;
  text-align: center;
}

.divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  height: 1px;
  background: #ccc;
}

.divider-text span {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  z-index: 9;
}
